import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Typography`}</h1>
    <hr></hr>
    <p>{`All of the metrics in Swarm Design System are based in our typography and our typographic scale.
Text is the most common way information is displayed in Meetup’s products, so we’ve developed
a polished typographic system that provides hierarchy and ensures our content is always readable.`}</p>
    <br />
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<>
<h1 className="text--display">Display Text</h1>
<h2 className="text--pageTitle">Page Title</h2>
<h3 className="text--big">Large Text</h3>
<h3 className="text--sectionTitle">Section Title</h3>
<h3 className="text--kickerText">Kicker Text</h3>
<a className="text--link" href="#">Link Text</a>
<p className="text--tiny">Tiny Text</p>
<p className="text--body">Body text</p>
<p className="text--small">Small text</p>
<div className="inverted" style={{ backgroundColor: 'black' }}>
    <h1 className="text--display">Display Text</h1>
    <h2 className="text--pageTitle">Page Title</h2>
    <h3 className="text--big">Large Text</h3>
    <h3 className="text--sectionTitle">Section Title</h3>
    <h3 className="text--kickerText">Kicker Text</h3>
    <a className="text--link" href="#">Link Text</a>
    <p className="text--tiny">Tiny Text</p>
    <p className="text--body">Body text</p>
    <p className="text--small">Small text</p>
</div>
</>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      